@import url('https://p.typekit.net/p.css?s=1&k=szs4sts&ht=tk&f=139.140.173.175.5474.5475&a=4274771&app=typekit&e=css');

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.com/af/06b3d8/00000000000000003b9aefb8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.com/af/06b3d8/00000000000000003b9aefb8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.com/af/06b3d8/00000000000000003b9aefb8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.com/af/023692/00000000000000003b9aefb9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.com/af/023692/00000000000000003b9aefb9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.com/af/023692/00000000000000003b9aefb9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.com/af/c7956e/00000000000000003b9aefc2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.com/af/c7956e/00000000000000003b9aefc2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.com/af/c7956e/00000000000000003b9aefc2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.com/af/125f73/00000000000000003b9aefc0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.com/af/125f73/00000000000000003b9aefc0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.com/af/125f73/00000000000000003b9aefc0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.com/af/08012d/00000000000000003b9aefbc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.com/af/08012d/00000000000000003b9aefbc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.com/af/08012d/00000000000000003b9aefbc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.com/af/4b7a95/00000000000000003b9aefbd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.com/af/4b7a95/00000000000000003b9aefbd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.com/af/4b7a95/00000000000000003b9aefbd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

.tk-proxima-nova {
  font-family: 'proxima-nova', sans-serif;
}

.subscription-collections {
  position : relative;
  padding : 4rem 0px;
}

.subscription-collections::after {
  right : 50%;
  left : 50%;
  margin-right : -50vw;
  margin-left : -50vw;
  width : 100vw;
  position : absolute;
  top : 0px;
  z-index : -1;
  display : block;
  height : 100%;
  content : "";
  background : rgb(241, 241, 241);
}

.subscription-collections__title {
  font-size : 24px;
  font-style : normal;
  font-weight : 700;
  letter-spacing : 0.02em;
  text-align : center;
  margin-bottom : 2.85rem;
}

.subscription-collections-list {
  display : flex;
  justify-content : center;
  flex-wrap : wrap;
}

.subscription-collections-list__product {
  padding : 1.75rem;
  background-color : rgb(255, 255, 255);
  display : flex;
  flex-direction : column;
}

.subscription-collections-list__product p {
  font-size : 16px;
  font-style : normal;
  font-weight : 600;
  letter-spacing : 0.02em;
  text-align : left;
  margin-bottom : 1.75rem;
}

.subscription-collections-list__product .button {
  margin : 0px auto;
}

.subscription-collections-list__product img {
  min-width : 100%;
}

.subscription-collections-list__product__title {
  display : flex;
  justify-content : space-between;
}

span.mcode {
  background-color: #fff;
  color: #0c8863;
  padding: 4px;
}

.subscription-collections-list__product__title h4, .subscription-collections-list__product__title span {
  color : rgb(20, 75, 60);
  font-size : 18px;
  font-style : normal;
  font-weight : 700;
  letter-spacing : 0.1em;
  text-align : left;
  text-transform : uppercase;
  margin-top : 1.75rem;
  margin-bottom : 0.5rem;
}
@media screen and (max-width: 767px) {
  .subscription-collections-list__product {
    min-width : 33.3333%;
    margin-top : 0px;
    margin-right : 2.25rem;
    margin-left : 2.25rem;
    margin-bottom : 1.15rem;
  }
}
@media screen and (min-width: 768px) {
  .subscription-collections-list__product {
    max-width : calc(33.3333% - 2.25rem);
  }

  .subscription-collections-list__product:not(:last-child) {
    margin-right : 2.25rem;
  }
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');


.product-option-img {
  width: 100px;
  height: 100px;
}

/*purchasing-option-description*/
.purchasing-option-description {
  position: relative;
  display: list-item;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #333333;
}

.product-details-section-label {
  margin: 0 0 4px;
  clear: both;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #333;
  font-size: 14px;
  font-family: proxima-nova, sans-serif;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  line-height: 1.5em;
}


body.no-scroll {
  overflow: hidden;
}

/* Drawer Css */

body.drawer-open {
  overflow: hidden;
}
.save-30-box {
  padding: 10px 0;
}
.cart-drawer-footer-content{
  position: relative;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);
}
@media (max-width: 767px) {
  .cart-drawer-footer-content{
    position: relative;
  }
  .cart-drawer-footer{
    flex-direction: row-reverse !important;
  }
  .cart-drawer-footer-content{
    min-height:180px;
  }
}
@media (min-width: 1600px) {
  .cart-drawer-wrap{
     max-width:450px;
  }
}
.cart-drawer-wrap{
  display: flex;
  flex-direction: column;
}
.field-title{
  margin: 0 0 4px;
  font-weight: 600;
  text-transform: uppercase;
  color: #333;
  font-size: 14px !important;
  text-align: left;
  text-transform: uppercase;
  font-family: 'proxima-nova', sans-serif;
}
.purchasingOption.active .purchasing-description p {
  color:#144b3c !important;
}
.modal-main .modal-close-button , .modal-main .modal-close-button:focus ,.modal-main .modal-close-button:active {
  border: none;
  outline: none;
  box-shadow: none;
}
.main-content {
  margin: 0 3% !important;
}
.add-to-basket-btn:hover , .add-to-basket-btn:focus, .add-to-basket-btn:active {
  background-color: #195f4c !important;
}
/* .zip-code-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
} */  
.p-relative {
  position: relative;
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

